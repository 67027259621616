<!-- Modal de confirmação pra deletar o plano -->
<template>
  <b-modal
    id="modal-delete-plan"
    centered
    size="lg"
    title="Deletar plano"
    ok-only
    ok-title="Exportar"
  >
    <b-card-text>
      <b-alert variant="danger" show>
        <h4 class="alert-heading">Atenção!</h4>
      </b-alert>
      <b-form-checkbox v-model="deletePlan" unchecked-value="1">
        Concordo em excluir este cliente
      </b-form-checkbox>
    </b-card-text>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="relief-success" class="mr-1" ok-only>
          Fechar
        </b-button>
        <b-button variant="relief-danger" :disabled="!deletePlan">
          Deletar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
        deletePlan: 0,
    };
  },
  components: {
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>