<template>
  <b-card>
    <div>
      <b-row class="p-1">
        <div v-if="!showCreate">
          <b-button variant="relief-success" class="mr-2" @click="showCreate = true">
            <feather-icon icon="PlusIcon" />
            Cadastrar
          </b-button>
        </div>
        <div v-else>
          <b-button variant="relief-danger" class="mr-2" @click="showCreate = false">
            <feather-icon icon="ListIcon" />
            Listar
          </b-button>
        </div>

        <div v-if="!showSearch" v-show="!showCreate">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisar"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else v-show="!showCreate">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>
      <div v-show="!showCreate">
        <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
            striped
            stacked="md"
            noDataText="Você ainda não possui clientes cadastrados."
            class="mt-2 responsive"
            id="tablePlans"
            :item="fetchPlans"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <b-thead class="text-center">
              <b-th>Nome do Plano</b-th>
              <b-th>Status</b-th>
              <b-th>Valor</b-th>
              <b-th>Data de registro</b-th>
              <b-th>Ação</b-th>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in plans">
                <b-td stacked-heading="Nome">{{ tr.name }}</b-td>
                <b-td class="text-center">
                  <b-badge variant="success" class="badge-glow">
                    Ativado
                  </b-badge>
                </b-td>
                <b-td stacked-heading="Valor">{{
                  tr.value | moneyFormat
                }}</b-td>
                <b-td stacked-heading="Data">
                  {{ tr.created_at | dateFormat }}
                </b-td>
                <b-td>
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-warning"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-b-modal.modal-delete-plan
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="tablePlans"
          class="mt-1"
        />
        <delete-plan />
      </div>
      <div v-show="showCreate">
        <div class="demo-vertical-spacing">
          <b-row>
            <b-col md="6" xl="6" class="p-1">
              <label> Nome do plano </label>
              <b-form-input
                placeholder="Nome"
                v-model="datePlan.name"
                id="name"
              />
            </b-col>
            <b-col md="6" xl="6" class="p-1">
              <label> Valor do plano </label>
              <b-form-input
                placeholder="Valor"
                v-model="datePlan.value"
                id="value"
                v-mask="['###.##', '##.##']"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" xl="3" class="p-1">
              <label> Valor por E-mail </label>
              <b-form-input
                placeholder="Valor"
                v-model="datePlan.value_email"
                id="name"
                v-mask="['##.##']"
              />
            </b-col>
            <b-col md="3" xl="3" class="p-1">
              <label> Valor por SMS </label>
              <b-form-input
                placeholder="Valor"
                v-model="datePlan.value_sms"
                id="value"
                v-mask="['##.##']"
              />
            </b-col>
            <b-col md="3" xl="3" class="p-1">
              <label> Valor por WhatsApp </label>
              <b-form-input
                placeholder="Valor"
                v-model="datePlan.value_whats"
                id="name"
                v-mask="['##.##']"
              />
            </b-col>
            <b-col md="3" xl="3" class="p-1">
              <label> Valor por Ligação </label>
              <b-form-input
                placeholder="Valor"
                v-model="datePlan.value_call"
                id="value"
                v-mask="['##.##']"
              />
            </b-col>
          </b-row>

          <b-row class="p-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-1"
              @click="storePlan"
            >
              Cadastrar
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              type="reset" 
              @click="showCreate = false"
            >
              Cancelar
            </b-button>
          </b-row>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import DeletePlan from "@/components/plans/DeletePlan";
// import vSelect from "vue-select";

export default {
  components: {
    Swiper,
    SwiperSlide,
    DeletePlan,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      search: null,

      isLoading: true,

      showSearch: false,

      showCreate: false,

      datePlan: {
        name: null,
        value: null,
        value_email: null,
        value_sms: null,
        value_whats: null,
        value_call: null
      },
      
      selected: null,
      options: [
        { value: null, text: "Please select some item" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Default Selected Option" },
        { value: "c", text: "This is another option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],

    };
  },
  computed: mapState("plans", ["plans", "pagination"]),
  methods: {
    ...mapActions("plans", ["store"]),
    storePlan() {
      this.store({
        ...this.datePlan,
      });
    },
    fetchPlans(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("plans/fetchPlans", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchPlans(true, value);
    },
  },
  created() {
    this.fetchPlans();
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchPlans(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
